import { FC } from 'react'
import { cx } from '../../utils'

interface ILogoLoader {
  className?: string
}

export const Loader: FC<ILogoLoader> = ({ className }) => {
  return (
    <div
      className={cx([
        'h-full w-full text-center py-40 inline-block',
        className
      ])}
    >
      <div className='relative inline-block'>
        <svg
          width='38'
          height='38'
          viewBox='0 0 38 38'
          xmlns='http://www.w3.org/2000/svg'
          stroke='#96989E'
        >
          <g fill='none' fillRule='evenodd'>
            <g transform='translate(1 1)' strokeWidth='2'>
              <circle strokeOpacity='.4' cx='18' cy='18' r='18' />
              <path d='M36 18c0-9.94-8.06-18-18-18'>
                <animateTransform
                  attributeName='transform'
                  type='rotate'
                  from='0 18 18'
                  to='360 18 18'
                  dur='1s'
                  repeatCount='indefinite'
                />
              </path>
            </g>
          </g>
        </svg>

        <img
          src='/images/atlantico-favicon.png'
          alt='Porta logo'
          className='transform -translate-x-1/2 -translate-y-1/2 absolute left-1/2 top-1/2'
          width={16}
        />
      </div>
    </div>
  )
}
